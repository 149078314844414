import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout/layout";
import PageTitle from "../components/page-title/page-title";

// import "./preguntas-frecuentes.scss";

export default function ConsultaPagoCompletado() {
    return (
        <React.Fragment>
            <SEO  title="Consulta Pago Completado"/>
            <Layout>
                <PageTitle text="El pedido ha sido tramitado" />
                <div>-POR HACER RELLENO DE CONDICIONES-</div>
                </Layout>
        </React.Fragment>
    );
}
